<template>
  <div>
    <b-row class="mt-2 mx-4 mb-2">
      <div class="inlineBlock label-wrap">
        <div class="label-text-wrap mb-0">
          <label class="formLabel mb-0 bold">{{ fieldLabel }}</label>
        </div>
      </div>
      <div class="mainWidth">
        <div
          class="mr-2 badge badge-additional">
          最新
        </div>
        <div v-if="type !== 100">
          <component
            :is="typeMap[type]"
            :fieldId='fieldId'
            :is_variable="is_variable"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :options.sync="options"
            :notice="notice"
            :writable="false"
            :view_template="view_template"
            :rules="rules"
            :messages="messages"
            :params="params"
            :downloadFormFile="downloadFormFile"
            :isOld="true"
            :controlFormatter="controlFormatter"
            :controlInputType="controlInputType"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            />
        </div>
        <div v-if="type === 100">
          <component
            :is="specialConponentNameList[view_template]"
            :fieldId='fieldId'
            :options.sync="options"
            :notice="notice"
            :writable="false"
            :messages="messages"
            :params="params"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :isOld="true"
            :controlFormatter="controlFormatter"
            :controlInputType="controlInputType"
            :nonePlace="true"
            :downloadFormFile="downloadFormFile"
            @kickDeleteFile="deleteFile($event)"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            />
        </div>

        <div
          v-if="messages.length > 0"
          class="ml-1 mt-1">
          <p
            v-for="(data, index) in messages" :key="index"
            class="mb-0">
            <span class="f-13" v-if="data.type === 2">{{data.message}}</span>
            <span class="f-13 ml-3" v-if="data.type === 4">
              ダウンロード：{{data.report_type_asset_id}}
              <b-link
                @click="downloadOpenFile(assetId(index), data.message)"
                class="mb-0">
                {{data.message}}
              </b-link>
            </span>
          </p>
        </div>

        <hr
          v-if="firstLog || changeLogs"
          class="change">

        <div
          v-if="firstLog || changeLogs"
          class="mr-2 badge badge-optional">
          変更履歴
        </div>
        <div v-if="firstLog">
          <p
            class="mb-0 mt-1"><span class="bold mr-2">計画変更申請前の初期データ</span> 登録日:{{firstLog.created_at}}</p>
          <div v-if="type !== 100">
            <component
              :is="typeMap[type]"
              :fieldId='fieldId'
              :is_variable="is_variable"
              :input_number="input_number"
              :min_input_number="min_input_number"
              :max_input_number="max_input_number"
              :options.sync="options"
              :notice="notice"
              :writable="false"
              :view_template="view_template"
              :rules="rules"
              :messages="messages"
              :params="params"
              :downloadFormFile="downloadFormFile"
              :isOld="true"
              :isLog="true"
              :isFirstLog="true"
              :logData="firstLog"
              :controlFormatter="controlFormatter"
              :controlInputType="controlInputType"
              />
          </div>
          <div v-else>
            <component
              :is="specialConponentNameList[view_template]"
              :fieldId='fieldId'
              :options.sync="options"
              :notice="notice"
              :writable="false"
              :messages="messages"
              :params="params"
              :input_number="input_number"
              :min_input_number="min_input_number"
              :max_input_number="max_input_number"
              :isOld="true"
              :isLog="true"
              :isFirstLog="true"
              :logData="firstLog"
              :controlFormatter="controlFormatter"
              :controlInputType="controlInputType"
              :nonePlace="true"
              :downloadFormFile="downloadFormFile"
              />
          </div>
        </div>

        <div v-if="changeLogs">
          <div v-for="(changeLog, index) in changeLogs" :key="index">
            <p
            class="mb-0 mt-1"><span class="bold mr-2">第{{changeLog.sort}}回 変更内容</span> 登録日:{{changeLog.created_at}}</p>
            <div v-if="type !== 100">
              <component
                :is="typeMap[type]"
                :fieldId='fieldId'
                :is_variable="is_variable"
                :input_number="input_number"
                :min_input_number="min_input_number"
                :max_input_number="max_input_number"
                :options.sync="options"
                :notice="notice"
                :writable="false"
                :view_template="view_template"
                :rules="rules"
                :messages="messages"
                :params="params"
                :downloadFormFile="downloadFormFile"
                :isOld="false"
                :isLog="true"
                :logData="changeLog"
                :controlFormatter="controlFormatter"
                :controlInputType="controlInputType"
                />
            </div>
            <div v-else>
              <component
                :is="specialConponentNameList[view_template]"
                :fieldId='fieldId'
                :options.sync="options"
                :notice="notice"
                :writable="false"
                :messages="messages"
                :params="params"
                :input_number="input_number"
                :min_input_number="min_input_number"
                :max_input_number="max_input_number"
                :isOld="false"
                :isLog="true"
                :logData="changeLog"
                :controlFormatter="controlFormatter"
                :controlInputType="controlInputType"
                :nonePlace="true"
                :downloadFormFile="downloadFormFile"
                />
            </div>
          </div>
        </div>

      </div>
    </b-row>
  </div>
</template>
<script>
import Input from '@/components/parts/application/Input.vue';
import Textarea from '@/components/parts/application/Textarea.vue';
import Check from '@/components/parts/application/Check.vue';
import Radio from '@/components/parts/application/Radio.vue';
import File from '@/components/parts/application/File.vue';
import Table from '@/components/parts/application/Table.vue';
import Datetime from '@/components/parts/application/Datetime.vue';
import Selectbox from '@/components/parts/application/Selectbox.vue';
import Calendar from '@/components/parts/application/Calendar.vue';
import Collaborator from '@/components/parts/application/special/Collaborator_1.vue';
import Participant from '@/components/parts/application/special/Participant_2.vue';
import Destination from '@/components/parts/application/special/J_Destination_3.vue';
import Iterm from '@/components/parts/application/special/I_Term_4.vue';
import CONST_STATUS from '@/constants/specialField';

export default {
  name: 'PartsApplicationText',
  components: {
    'v-app-input': Input,
    'v-app-textarea': Textarea,
    'v-app-check': Check,
    'v-app-radio': Radio,
    'v-app-file': File,
    'v-app-table': Table,
    'v-app-datetime': Datetime,
    'v-app-selectbox': Selectbox,
    'a-app-calendar': Calendar,
    'v-app-collaborator': Collaborator,
    'v-app-participant': Participant,
    'v-app-destination': Destination,
    'v-app-i-term': Iterm,
  },
  props: {
    fieldId: { type: Number },
    fieldLabel: { type: String },
    description: { type: String, default: '' },
    notice: { type: String, default: '' },
    type: { type: Number },
    is_required: { type: [Boolean, Number], default: false },
    // 記入項目マスタ
    label: { type: String, default: '' },
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    view_template: { type: Number, default: 1 },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    assets: { type: [Object, Array], default: () => {} },
    rules: { type: [Object, Array], fedault: () => {} },
    messages: { type: [Object, Array], default: () => [] },
    params: { type: [Object, Array], default: () => [] },
    downloadFormFile: { type: Function },
    downloadOpenFile: { type: Function },
    changeLogs: {},
    firstLog: {},
  },
  data() {
    return {
      typeMap: {
        1: 'v-app-input',
        2: 'v-app-textarea',
        3: 'v-app-check',
        4: 'v-app-radio',
        5: 'v-app-selectbox',
        6: 'v-app-file',
        7: 'v-app-table',
        8: 'v-app-datetime',
        9: 'a-app-calendar',
      },
    };
  },
  methods: {
    controlInputType(ruleIdList) {
      if (ruleIdList.includes(17) || ruleIdList.includes(18)) {
        return 'tel';
      }
      return 'text';
    },
    controlFormatter(ruleIdList) {
      // 半角数字のみ可
      if (ruleIdList.includes(17)) {
        return this.onlyHalfSizeNumber;
      }
      if (ruleIdList.includes(18)) {
        return this.onlyHalfSizeDate;
      }
      return null;
    },
  },
  computed: {
    specialConponentNameList() {
      return CONST_STATUS.COMPONENT_NAMES;
    },
    apptypeId() {
      return this.$store.state.gasApplicationForm.application_type_id;
    },
    assetId() {
      return (index) => {
        if (this.messages[index].report_type_asset_id) {
          return this.messages[index].report_type_asset_id;
        }
        return this.messages[index].application_type_asset_id;
      };
    },
  },
};
</script>

<style scoped>
  .titleWrap {
    padding: 8px 5px 8px 10px;
    border: 1px solid #ddd;
  }
  .head {
    background-color: #122889;
    width: 5px;
    height: 22px;
  }
  .title {
    font-size: 126%;
    line-height: 1.2em;
  }

  .formLabel {
    white-space: pre-line;
    top: 8px;
  }

  .label {
    width: 260px;
    max-width: 260px;
  }

  .mainWidth {
    width: 730px;
    max-width: 730px;
  }

  .fileNotice {
    font-size: 13px;
  }

  .message_1 {
    font-size: 13px;
  }

  .form-file-line {
    width: 100%;
  }

  .change-btn {
    margin-top: 10px;
    width: 100px;
    height: 40px !important;
    font-size: 14px !important;
    font-weight: bold;
    background-color: #fff !important;
    border-radius: 18px;
  }

  .on-change {
    border: 2px solid #293CB0 !important;
    color: #293CB0 !important;
  }

  .off-change {
    border: 2px solid #8E8E8E !important;
    color: #8E8E8E !important;
  }

  hr.change {
    border-top: 3px double;
  }

  .label-wrap {
    width: 240px;
    margin-right: 30px;
  }

  .label-text-wrap {
    width: 240px;
  }

  .notice-change {
    font-size: 14px;
    color: #dc3545;
  }

</style>
