<template>
  <div class="mx-auto contentsWidth mt-4">
    <div class="">
      <p class="title mb-0">{{base.year}}年度 {{base.name}} - 計画変更履歴</p>
      <hr class="title">
    </div>

    <div
      class="mb-3 mx-auto contentsWidth">
      <div
        v-for="(field, index) in fields" :key="index"
        class="col-12 px-0">
        <v-app-field-log
          :fieldId='field.field_id'
          :fieldLabel="field.label"
          :fieldType="field.type"
          :description="field.description"
          :notice="field.notice"
          :is_required="field.is_required"
          :type="field.type"
          :label="field.label"
          :is_variable="field.is_variable"
          :input_number="field.input_number"
          :min_input_number="field.min_input_number"
          :max_input_number="field.max_input_number"
          :view_template="field.view_template"
          :options.sync="field.options"
          :rules="field.rules"
          :messages="field.messages"
          :params="field.params"
          :downloadFormFile="downloadFormFile"
          :changeLogs="changeLogs[field.field_id]"
          :firstLog="firstLog[field.field_id]"
        />
        <hr>
      </div>
    </div>

    <div class="bottonArea mt-3 mb-3 flex flexCenter">
      <b-button
        class="btn btn-lg mr-4 bold"
        to="/cms/applications/change/log/list">
        一覧に戻る
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import FieldLog from '@/components/parts/application/FieldChangeLog.vue';
// import CONST_STATUS from '@/constants/appStatus';
import USECASE from '@/constants/usecase';

export default {
  name: 'cmsApplicationEdit',
  components: {
    'v-app-field-log': FieldLog,
  },
  data() {
    return {
      changePlanId: null,
      sort: null,
      // 申請データ
      base: {},
      change: {},
      // 入力欄データ
      fields: {},
      usecases: {},
      constUsecases: USECASE.USECASE,
      isReturn: true,
      mailInfo: {},
      newStatus: null,
      reason: '',
      changeLogs: {},
      firstLog: {},
    };
  },
  methods: {
    async fetchApptype(appId) {
      this.$store.dispatch('page/onLoading');
      const param = {
        appId,
      };
      const response = await api.send('/api/cms/applications/change/view', param)
        .catch((err) => {
          console.error(err);
        });

      this.$store.dispatch('page/offLoading');
      if (!response) {
        // await this.alert('申請のデータを取得できませんでした。');
        // this.$router.push({ path: 'cms/applications/list' });
        return;
      }
      const { base } = response.data;
      this.base = base;
      this.year = base.year;
      this.name = base.name;
      this.applicationTypeId = base.application_type_id;
      this.close_datetime = base.close_datetime;
      this.assets = response.data.assets;
      const { data } = response;
      this.base = data.base;
      this.fields = data.fields;
      this.changeLogs = data.changeLogs;
      this.firstLog = data.firstLog;
      const dbDataApp = { fields: data.fields, answers: data.answers };
      // const dbDataNew = { fields: data.fields, answers: {} };
      this.$store.commit('gasApplicationOldInput/setDbDataNoPage', dbDataApp);
      // this.$store.commit('gasApplicationInput/setDbDataNoPage', dbDataNew);
    },
    async downloadOpenFile(assetId, assetName) {
      const param = {
        assetId,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/download/file/form', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async downloadFormFile(fieldId, optionId, fileName, isOld, isLog, sort) {
      const param = {
        application_id: this.base.id,
        user_id: this.base.user_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: this.$store.state.gasApplicationInput.answer_no, // ファイルは当面１つ固定
        is_old: isOld,
        sort,
      };
      let url;
      if (isLog) {
        url = '/api/cms/applications/change/download/logFile';
      } else {
        url = '/api/cms/applications/download/uploadedFile';
      }

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      this.$store.commit('gasApplicationInput/setAnsNum', 1);
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async nextApp() {
      const newIndex = this.filterdIdListIndex + 1;
      this.$router.push({ path: `/cms/applications/change/log/view/${this.filterdIdList[newIndex]}` });
      this.$store.dispatch('page/onLoading');
      this.applicationId = this.filterdIdList[newIndex];
      await this.fetchApptype(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    async prevApp() {
      const newIndex = this.filterdIdListIndex - 1;
      this.$router.push({ path: `/cms/applications/change/log/view/${this.filterdIdList[newIndex]}` });
      this.$store.dispatch('page/onLoading');
      this.applicationId = this.filterdIdList[newIndex];
      await this.fetchApptype(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
  },
  computed: {
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    filterdIdList() {
      return this.$store.state.cmsApplicationSearch.filterdIdList;
    },
    filterdIdListIndex() {
      return this.filterdIdList.indexOf(Number(this.applicationId));
    },
    hasNextApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex + 1;
      return newIndex < this.filterdIdList.length;
    },
    hasPrevApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex - 1;
      return newIndex >= 0;
    },
    onGoing() {
      if (this.base.result_announcement_datetime === null) {
        return true;
      }
      const now = moment();
      const end = moment(this.base.result_announcement_datetime);
      return now.isBefore(end);
    },
  },
  // ロード画面
  async created() {
    const { appId } = this.$route.params;
    this.applicationId = appId;
    await this.fetchApptype(appId);
  },
};
</script>

<style scoped>
  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  .subject {
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
    width: 1080px;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
